.user-informations {
  height: 100%;
  padding: 62px 80px;
  background-color: $color-dark-white;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .back-button {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    font-size: 14px;
    font-weight: bold;
    justify-content: space-between;
    column-gap: 5px;
    padding: 0;
  }

  .page-title {
    margin-top: 65px;
    margin-bottom: 40px;
  }

  .content {
    max-width: 848px;
    margin: 0 auto;
    position: relative;
    top: -30px;
    height: auto;
    min-height: 100vh;

    .inner-content {
      background-color: white;
      padding: 30px 107px;
      margin-bottom: 20px;
      .description {
        font-weight: 400;
        font-size: 14px;
        color: $color-charcoal-grey;
      }
      .informations {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        .information {
          color: $color-dark-grey;
          .value {
            font-weight: 600;
          }
        }
      }
      .quote-content {
        width: 100%;
        padding-top: 20px;
        .quote-item {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          background-color: rgb(245, 245, 245);
          padding: 8px;
          padding-inline: 20px;
          margin-bottom: 10px;
          .id {
            width: 35%;
          }
          .date {
            width: 20%;
            text-align: center;
          }
          .price {
            width: 20%;
            text-align: right;
          }
        }
      }
    }
  }
}

@include mq($until: tablet) {
  .user-informations {
    padding: 20px 24px;
    .content {
      top: 0;
      margin-top: 24px;
    }
    .page-title {
      margin-top: 24px;
      margin-bottom: 12px;
    }
    .content {
      .inner-content {
        .inner-content-header {
          margin: 0;
          margin-bottom: 8px;
        }
        padding: 32px 24px;
      }
    }
  }
}
