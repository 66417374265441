.settingsbtn {
  position: relative;

  &.inverted {
    .icon {
      &.closed {
        background-color: $color-dark-grey;
      }
      &.open {
        background-color: white;
      }
    }
  }
  .icon {
    border-radius: 4px;
    border: none;
    padding: 0;
    height: 45px;
    width: 45px;
    align-self: flex-end;
  }

  .menu {
    -webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.07);
    box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.07);
    background-color: $color-white;
    border-radius: 4px;
    color: black;
    width: 300px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    row-gap: 19px;
    padding: 16px 40px;
    z-index: 9;

    .option {
      cursor: pointer;
      display: flex;
      background-color: transparent;
      border: none;
      align-items: center;
      justify-content: flex-start;
      p {
        text-transform: none;
        margin: 0;
      }

      img {
        padding-right: 13px;
        width: 31px;
      }
    }
  }
}

.product-edit,
.product-detail,
.list-users,
.add-user,
.search {
  .icon {
    &.open {
      background-color: $color-dark-grey;
    }
    &.closed {
      background-color: white;
    }
  }
}
.dashboard,
.training {
  .icon {
    &.open {
      background-color: white;
    }
    &.closed {
      background-color: $color-dark-grey;
    }
  }
}
