.restricted {
  @include gradientPanelLeft;
  min-height: 100vh;
  display: flex;
  flex-direction: row;

  &-intro {
    @include sidebar();
    &-north {
      img {
        width: 200px;
      }
    }
    &-south {
      justify-content: center;
      display: flex;
      flex-direction: column;
      flex: 1;
      p {
        width: 300px;
        opacity: 0.7;
        font-weight: 400;
        font-size: 14px;
      }
    }
    @include mq($until: tablet) {
      display: none;
    }
  }
  &-content {
    margin-right: 80px;
    padding-bottom: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;

    @include mq($until: tablet) {
      margin-right: 0;
    }

    &-info {
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 5rem;
      color: white;
      font-size: 18px;
      font-weight: 700;
      line-height: 36px;
      width: 70%;
      margin: 40px auto 0;

      a:hover {
        color: white;
      }
      @include mq($until: tablet) {
        font-size: 18px;
      }

      h1 {
        font-size: 36px;
        @include mq($until: tablet) {
          font-size: 26px;
        }

      }
      &-support {
        a {
          color: white;
          text-decoration: underline;
        }
      }
      &-download {
        display: flex;
        gap: 1rem;
        font-size: 24px;
        justify-content: center;
        font-weight: 500;
        color: white;
      }
    }
  }
}