.dashboard {
  @include gradientPanelLeft;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  &-header {
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  &-intro {
    @include sidebar();
    &-north {
      img {
        width: 200px;
      }
    }
    &-south {
      justify-content: center;
      display: flex;
      flex-direction: column;
      flex: 1;
      p {
        width: 300px;
        opacity: 0.7;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
  &-content {
    font-size: 14px;
    margin-right: 80px;
    padding-bottom: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    .loading {
      height: 100%;
      color: $color-white;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .product-selector {
      img.product-display--img {
        height: 100px;
      }
      .carousel .slider-wrapper.axis-horizontal .slider .slide {
        display: flex;
        align-items: center;
      }
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 120px;

      .hidden {
        visibility: hidden;
      }

      button {
        background-color: transparent;
        border: none;
      }

      &--item {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        width: fit-content;
        img {
          opacity: 0.4;
          padding: 10px;
          &.current {
            opacity: 1;
          }
        }
        button {
          width: fit-content;
          align-self: center;
          padding: 0;
          text-transform: uppercase;
          line-height: 26px;
          background: none;
          border: none;
          color: $color-white;
          opacity: 0.5;

          &.current {
            opacity: 1;
            font-weight: 700;
            border-bottom: 2px solid $color-white;
          }
        }
      }
    }
  }
  &-notice {
    display: none;
  }
}

@include mq($from: desktop-large) {
  .dashboard {
    &-content {
      .product-selector {
        img.product-display--img {
          object-fit: contain;
          width: auto;
          max-width: 100%;
        }
      }
    }
  }
}

@include mq($until: desktop-large) {
  .dashboard {
    &-content {
      .product-selector {
        img.product-display--img {
          object-fit: contain;
          width: auto;
          max-width: 100%;
        }
      }
    }
  }
}
@include mq($from: desktop-large) {
  .dashboard {
    &-content {
      .product-selector {
        .carousel.carousel-slider {
          width: 700px !important;
        }
      }
    }
  }
}

@include mq($until: desktop) {
  .dashboard {
    &-content {
      .product-selector {
        img.product-display--img {
          object-fit: contain;
          width: auto;
          max-width: 100%;
        }
      }
    }
  }
}

@include mq($until: desktop-sm) {
  .dashboard {
    &-content {
      .product-selector {
        img.product-display--img {
          object-fit: contain;
          width: auto;
          max-width: 100%;
        }
      }
    }
  }
}

@include mq($until: tablet) {
  .dashboard {
    &-header {
      justify-content: unset;
    }
    overflow: hidden;
    &-intro {
      display: none;
    }
    &-content {
      padding-bottom: 30px;
      margin-right: 0;
      height: auto;
      .product-selector {
        .navigator {
          display: none;
        }
        .carousel-slider {
          width: 100%;
          img {
            display: none;
          }
        }
      }
    }
    &-notice {
      padding: 24px;
      position: absolute;
      width: 100vw;
      height: 100vh;
      background-color: $color-white;
      z-index: 2;
      display: flex;
      flex-direction: column;
      &-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 38px;
      }
      &-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        h2 {
          margin-bottom: 40px;
        }
        p {
          color: $color-charcoal-grey;
          opacity: 0.7;
        }
        button {
          margin-top: 70px;
          background: $color-primary-blue;
          color: $color-white;
          padding: 10px 28px;
          border-radius: 4px;
          font-size: 14px;
          border: none;
          text-transform: uppercase;
          font-weight: 700;
          line-height: 25px;
          align-self: center;
          text-decoration: none;
        }
      }
    }
  }
}
