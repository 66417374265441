body {
  font-family: 'Open Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: auto;
  @include body;
}
h1 {
  @include h1;
}
h2 {
  @include h2;
}
h3 {
  @include h3;
}
h4 {
  @include h4;
}
h5 {
  @include h5;
}

.subtitle {
  @include title-subtitle;
}

b, strong {
  @include bold;
}

.small {
  @include small;
}
.small-bold {
  @include small-bold
}

.extrasmall {
  @include extra-small;
}
.extrasmall-bold {
  @include extra-small-bold;
}
