.product-display {
  min-height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  flex: 1;
  img {
    height: 30%;
    max-height: 125px;
  }
  &--title {
    display: flex;
    align-items: center;
    text-transform: uppercase;

    button {
      background: $color-dark-grey;
      border: none;
      border-radius: 4px;
      width: 46px;
      height: 46px;
      justify-content: center;
      display: flex;
      align-items: center;
    }

    &-container {
      align-items: center;
      display: flex;
      flex-direction: column;

      h2 {
        margin-bottom: 0;
        font-size: 26px;
        font-weight: 700;
        word-spacing: 3px;
      }
      p {
        margin-top: 0;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        text-align: center;
        margin-bottom: 23px;
      }
    }
  }

  &--description {
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    width: 80%;
  }

  .image-content {
    width: 32%;
    max-width: 500px;
    min-width: 310px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    svg {
      position: absolute;
      transform: scale(1.8);
    }
    .circle-big {
      stroke-width: 0.15;
      stroke-linecap: square;
      stroke: #ffffff;
      fill: none;
      transform: rotate(200deg);
      transform-origin: 50% 50%;
    }
    .circle-small {
      fill: none;
      stroke-width: 0.1;
      stroke-linecap: square;
      stroke: #ffff;
      transform: scale(0.9) rotate(20deg);
      transform-origin: 50% 50%;
      opacity: 0.35;
    }
    &-control {
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 1;
      cursor: pointer;

      .btn-see-more {
        background-color: inherit;
        color: white;
        border: #ffffff 2px solid;
        border-radius: 5px;
        padding: 15px 30px;
        text-transform: uppercase;
        margin-top: 15px;
        font-weight: bolder;
      }
    }
  }
}

@include mq($from: tablet) {
  .image-content {
    &.test-1 {
      .circle-big {
        transform: rotate(320deg);
      }

      .circle-small {
        transform: scale(0.9) rotate(126deg);
      }
    }

    &.test-2 {
      .circle-big {
        transform: rotate(92deg);
      }

      .circle-small {
        transform: scale(0.9) rotate(280deg);
      }
    }
  }
}

@include mq($until: tablet) {
  .product-display {
    .image-content {
      width: 70%;
      .circle-big {
        transform: rotate(180deg);
      }
      .circle-small {
        transform: scale(0.96) rotate(90deg);
      }
      svg {
        position: absolute;
        transform: scale(2);
        top: -15%;
      }
    }
    &--description {
      display: none;
    }
  }
  .product-selector {
    column-gap: 20px;
    justify-content: space-evenly !important;
    .carousel-root,
    .carousel.carousel-slider {
      width: 100% !important;
    }
    &--item {
      margin: 0 !important;
      width: unset !important;
      button {
        font-size: 16px;
        font-weight: 700;
      }
    }
  }
}

.rotateIn {
  &.rotateIn0 {
    animation-name: rotateIn0;
  }
  &.rotateIn1 {
    animation-name: rotateIn1;
  }
  &.rotateIn2 {
    animation-name: rotateIn2;
  }
  &.rotateIn3 {
    animation-name: rotateIn3;
  }
  animation-duration: 0.7s;
  animation-fill-mode: both;
}

@keyframes rotateIn0 {
  @include animateCircle;
}
@keyframes rotateIn1 {
  @include animateCircle;
}

@keyframes rotateIn2 {
  @include animateCircle;
}

@keyframes rotateIn3 {
  @include animateCircle;
}

@include mq($until: tablet) {
  .product-display {
    img.product-display--img {
      max-height: 160px;
    }
  }
  .rotateIn {
    animation: none;
  }
}
@include mq($from: desktop) {
  .product-display {
    .image-content {
      width: 28%;
    }
    img {
      max-height: 150px;
    }
  }
}
@include mq($from: desktop-large) {
  .product-display {
    .image-content {
      width: 24%;
    }
    img {
      max-height: 150px;
    }
  }
}

@include mq($from: desktop-xlarge) {
  .product-display {
    .image-content {
      width: 25%;
    }
    img {
      max-height: 200px;
    }
  }
}
