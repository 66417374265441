// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------
@mixin skew($direction, $largeside, $color) {
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: -$skew-height;
    left: 0;
    z-index: 0;
    width: 100%;
    height: calc(#{$skew-height} + 1px);
    background-color: $color;
    transition: clip-path 0.5s ease-in-out;
    @if $direction == "bottom" {
      @if $largeside == "right" {
        clip-path: polygon(0 0, 100% $skew-height, 100% 100%, 0% 100%);
      }
    }
    @if $direction == "top" {
      @if $largeside == "right" {
        clip-path: polygon(0 $skew-height, 100% 0, 100% 100%, 0% 100%);
      }
    }
  }
}


@mixin debug-layout {
  outline: 1px solid black;
  > * {
    outline: 1px solid red;
    > * {
      outline: 1px solid green;
      > * {
        outline: 1px solid pink;
        > * {
          outline: 1px solid orange;
        }
      }
    }
  }
}

/** TYPOGRAPHIE */
@mixin text-form {
  font-size: toEm(16px);
  font-weight: normal;
  font-style: normal;
}
@mixin h1 {
  font-size: toEm(36px);
  font-weight: 600;
  line-height: 48px;
}
@mixin h2 {
  font-size: toEm(24px);
  font-weight: 600;
  line-height: 36px;
}
@mixin h3 {
  font-size: toEm(16px);  
  font-weight: 600;
  line-height: 22px;
}
@mixin h4 {
  font-size: toEm(14px);
  line-height: 36px;
}
@mixin h5 {
  font-size: toEm(16px);
  font-weight: 500;
  font-style: normal;
  line-height: 29px;
}
@mixin title-subtitle {
  font-size: toEm(20px);
  font-weight: normal;
  font-style: normal;
  line-height: 26px;
}

@mixin body {
  font-size: toEm(16px);
  font-weight: normal;
  font-style: normal;
  line-height: 25px;
}
@mixin bold {
  font-size: toEm(16px);
  font-weight: 500;
  font-style: normal;
  line-height: 25px;
}
@mixin small {
  font-size: toEm(14px);
  font-weight: normal;
  font-style: normal;
  line-height: 20px;
}
@mixin small-bold {
  font-size: toEm(14px);
  font-weight: 500;
  font-style: normal;
  line-height: 20px;
}
@mixin extra-small-caps {
  font-size: toEm(12px);
  font-weight: 400;
  font-style: normal;
  line-height: 18px;
  text-transform: uppercase;
}
@mixin extra-small-caps-bold {
  font-size: toEm(12px);
  font-weight: 500;
  font-style: normal;
  line-height: 18px;
  text-transform: uppercase;
}
@mixin extra-small {
  font-size: toEm(12px);
  font-weight: 400;
  font-style: normal;
  line-height: 13px;
}
@mixin extra-small-bold {
  font-size: toEm(12px);
  font-weight: 500;
  font-style: normal;
  line-height: 13px;
}


@mixin sidebar {
  width: 490px;
  background-color: white;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
  padding: 60px 80px;
  display: flex;
  flex-direction: column;
}
