// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

/**
 * Basic
 */
html,
body,
#root {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

a,
button {
  cursor: pointer;
}

*:focus {
  outline: none;
}

button.disabled {
  pointer-events: none;
}

@include mq($until: tablet) {
  html,
  body,
  #root {
    height: 100vh;
    min-height: 100vh;
    min-height: -webkit-fill-available;
  }
}
