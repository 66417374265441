.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cookie-consent {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px 40px;
  justify-content: space-between;
  background-color: $color-grayscale-gray-90;
  z-index: 10000;

  .content {
    color: white;
  }
  .button-wrapper {
    display: flex;
    column-gap: 20px;

    .decline {
      background-color: transparent;
      color: white;
    }
  }
}

@include mq($until: tablet) {
  .cookie-consent {
    flex-direction: column;
    row-gap: 30px;
  }
}
