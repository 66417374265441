.list-users {
  background-color: #f6f6f6;
  min-height: 100vh;
  height: auto;
  display: flex;
  flex-direction: row;
  &-header {
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .custom-header a,
    p {
      color: $color-charcoal-grey !important;
    }
  }
  &-intro {
    max-width: 200px;
    height: 100%;
    background-color: inherit;
    position: relative;
    padding: 47px 60px;
    display: flex;
    flex-direction: column;
    &-north {
      img {
        width: 66px;
      }
    }
  }
  &-content {
    font-size: 14px;
    margin-right: 80px;
    padding-bottom: 70px;
    width: 100%;
    display: flex;
    flex-direction: column;
    .loader-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 400px;
    }
    .list-users-result {
      margin-block: 20px;
      margin-top: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      width: 90%;
      .logo {
        width: 30px;
        height: 20px;
      }

      @include mq($until: tablet) {
        .small {
          width: 150px;
        }
        .search-mobile {
          display: flex;
          justify-content: end;
          width: 100%;
          margin-right: 16px;
        }
      }
      .action-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 76%;
        margin-bottom: 20px;
        @include mq($until: tablet) {
          width: 100%;
        }
        .container-cta {
          display: flex;
          gap: 20px;
          @include mq($until: tablet) {
            position: absolute;
            right: 10px;
          }
          .button {
            display: flex;
            line-height: 20px;
            vertical-align: middle;
          }

          .search-user-input {
            display: flex;
            width: 240px;
            height: 44px;
            padding: 24px 16px;
            justify-content: space-between;
            align-items: center;
            flex-shrink: 0;
            border-radius: 4px;
            background: white;
            border: none;
          }
        }
      }
    }
    .filter-open {
      display: flex;
      flex-direction: column;
      width: 76%;
      @include mq($until: tablet) {
        padding-left: 10%;
        width: 100%;
      }
      .top-part {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-bottom: 20px;
        gap: 20px;
        @include mq($until: tablet) {
          display: grid;
          grid-template-columns: 1fr 1fr;
        }
      }
      .active-filter {
        margin-bottom: 30px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        @include mq($until: tablet) {
          margin-bottom: 0;
        }
        .puce-active-filter {
          padding: 8px 12px;
          border: 1px solid $color-primary-blue;
          border-radius: 22px;
          background-color: white;
          color: $color-primary-blue;

          .icon-remove-filter {
            margin-left: 10px;
            color: $color-primary-blue;
            font-weight: '900';
            font-size: 15px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.table {
  table-layout: fixed;
  border-collapse: collapse;
  margin-bottom: 20px;
  input[type=checkbox] {
    width: 20px;
    height: 25px;
  }
  .user-count {
    font-size: 19px;
    font-weight: 800;
    margin-block: 10px;
    max-width: 100% !important;
    text-align: right;
    padding-right: 20px;
    color: #807e7e;
  }

  .rounded-restricted {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: red;
    display: block;
    margin-left: 5px;
  }

  .align-restricted {
    display: flex;
    align-items: center;
  }

  th,
  td {
    overflow: hidden;
    table-layout: fixed;
    padding: 10px;
  }

  .cursor-pointer {
    display: flex;
  }

  td,
  div {
    max-width: 120px;
    overflow: hidden;
    white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis;
    font-size: 14px;
    @include mq($until: tablet) {
      max-width: 62px;
    }
    &.small {
      width: 60px;
      text-align: center;
      overflow: hidden;

      span {
        font-weight: bold;
      }
      .cta {
        max-width: 40px;
        cursor: pointer;
      }
    }
    .block {
      width: 50px;
      height: 30px;
      background-color: rgba(20, 69, 155, 0.2);
      border-radius: 4px;
      padding: 4px 10px;
      font-weight: 500;
    }
  }

  .mobile-content {
    display: block;
    border: 1px solid rgba(20, 69, 155, 0.20);
    width: 51px;
    border-radius: 4px;
    text-align: center;
    background-color: rgba(20, 69, 155, 0.20);
  }

  th {
    background-color: #ffffff;
  }

  tr {
    background-color: rgba(255, 255, 255, 0.4);
    margin-bottom: 30px;
  }

  tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }

  .selected {
    background-color: $color-admin-gray !important;
  }
  .sorted-icn {
    margin-left: 8px;
    margin-right: 9px;
  }
  .dsc-icn {
    vertical-align: baseline;
  }
  .asc-icn {
    vertical-align: super;
  }
}
.pagination {
  position: absolute;
  right: 15%;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  li {
    margin-inline: 5px;
  }

  .active {
    font-weight: 900;
  }
}

.container-center {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@include mq($until: tablet) {
  .list-users {
    padding: unset;
    &-intro {
      display: none;
    }

    &-content {
      top: 0;
      margin-right: unset;
      background-color: #f6f6f6;

      .small-mobile {
        display: block;
        width: 90px;
      }
      .list-users-result {
        flex: 1;
        margin: 0 auto;
        .hide-mobile {
          display: none;
          width: 0px;
          h2 {
            color: $color-dark-grey;
          }
        }
        .button-notfound {
          background-color: #14459b;
          width: 70px;
        }
        .button-show-more {
          border: 0.2px $color-grey solid;
        }
        .action-header {
          margin-top: 40px;
          padding-inline: 32px;
          padding-left: 0;
          align-items: center;
          @include mq($until: tablet) {
            position: relative;
            h2 {
              position: relative;
            }
          }
          h1 {
            margin-top: 0;
            margin-bottom: 10px;
            color: $color-dark-grey;
            font-size: 28px;
          }

          .b-btn {
            border: none;
            padding: 15px 10px;
            .logo {
              width: 30px;
              height: 25px;
            }
          }
          .secondary {
            background-color: white;
            padding-left: 16px;
            padding-right: 16px;
            .logo {
              width: 20px;
              height: 20px;
            }
          }
        }

        .bottom-part {
          padding: 10px;
          margin-block: 30px;
          display: flex;
          flex-direction: column-reverse;
          gap: 40px;
          h2 {
            margin-bottom: 0;
          }
          .preview-card .delete-logo {
            visibility: visible;
          }
          .preview-card-comment .delete-logo {
            visibility: visible;
          }

          .left-part {
            width: 100%;
            .more-detail-container {
              width: 100%;
            }
            .activity-text {
              text-align: left;
            }

            .button-more {
              margin-bottom: 40px;
            }
          }
        }
      }
    }
    .table {
      th {
        padding: 15px;
        width: 50px !important;
      }
      .small {
        width: 30px;
      }
      .separator {
        width: 90%;
        height: 1px;
        background-color: #a49e9e;
        padding: 0.5px;
      }

      .more-info-row-mobile {
        width: 80%;
        background-color: rgba(255, 255, 255, 0.4);
        border-bottom: 0.5px solid #a49e9e;

        td:first-child {
          font-weight: bold;
        }
      }
    }
  }
}
