.product-detail {
  background-color: $color-dark-white;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;

  form {
    display: inherit;
    flex: 1;
  }

  &-header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .custom-header a,
    p {
      color: $color-charcoal-grey !important;
    }
  }
  &-subheader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 70px;
    margin-bottom: 27px;
    h2 {
      font-size: 26px;
    }
    &-control {
      display: flex;
      column-gap: 19px;
      button {
        padding: 12px;
        margin: 0;
        border: none;
        border-radius: 4px;
      }
      &-delete {
        background-color: $color-white;
      }
      &-edit {
        background-color: $color-primary-blue;
        &.disabled {
          pointer-events: none;
          opacity: 0.4;
        }
      }
    }
  }
  &-intro {
    .custom-header {
      display: none;
    }
    .product-detail-header {
      display: none;
    }
    @include sidebar();
    @include gradientPanelLeft;
    padding-right: 120px;
    color: $color-white;
    justify-content: space-between;
    &-mobile {
      display: none;
    }
    &-north {
      display: flex;
      flex: 1;
      flex-direction: column;
      .logo {
        align-self: flex-start;
      }
      .container-3d {
        display: flex;
        justify-content: center;
      }
      .image {
        width: 100%;
      }
      h1 {
        margin-top: 60px;
      }
      h2 {
        font-size: 26px;
      }
      p {
        font-size: 14px;
        opacity: 0.7;
        flex: 1;
      }
    }
    &-south {
      display: flex;
      justify-content: space-between;
      .hidden {
        visibility: hidden;
      }
      .navigation {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      a {
        border: none;
        color: $color-white;
        text-transform: uppercase;
      }
      p {
        font-size: 14px;
        margin-bottom: 0;
      }
    }
  }
  &-content {
    &.isMenuDeleteOpen {
      margin-bottom: 110px;
    }
    flex: 1;
    padding-left: 100px;
    overflow: scroll;
    padding-right: 80px;
  }
  &-action {
    z-index: 1;
    position: fixed;
    bottom: 0;
    background-color: $color-white;
    height: 110px;
    display: flex;
    width: 100%;
    box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.05);
    align-items: center;
    justify-content: flex-end;
    padding: 32px 80px;
    column-gap: 40px;
    font-size: 14px;
    &-item {
      margin-right: 50px;
      color: $color-charcoal-grey;
    }
    &-cancel {
      font-weight: bold;
      color: $color-primary-blue;
      text-transform: uppercase;
    }
    &-delete {
      button {
        padding: 10px 30px;
        text-transform: uppercase;
        color: $color-white;
        margin: 0;
        border: none;
        border-radius: 4px;
        background-color: $color-primary-blue;
        font-weight: bold;
      }
    }
  }
  &-styles {
    .media-item-header-content {
      .brush {
        margin-left: 46px;
      }
      margin-top: 50px;
      background-color: $color-charcoal-grey;
      color: $color-white;
      .download {
        margin-left: 35px;
      }
    }
  }
}

@include mq($until: desktop-large) {
  .product-detail-intro-north {
    h2 {
      line-height: 31px;
    }
    p {
      line-height: 19px;
    }
  }
}

@include mq($until: tablet) {
  form {
    flex-direction: column;
  }
  .product-detail {
    flex-direction: column;
    overflow-y: scroll;
    &-intro {
      width: 100%;
      clip-path: none;
      padding: 0;
      .custom-header-mobile {
        width: unset;
      }
    }
    &-header {
      justify-content: unset;
    }
    &-intro {
      &-north,
      &-south {
        display: none;
      }
      &-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 36px;
        margin: 60px;
        &-north {
          img {
            max-width: 200px;
          }
        }
        &-middle {
          display: flex;
          flex-direction: row;
          align-items: center;
          column-gap: 80px;
          h1 {
            font-size: 26px;
            font-weight: 700;
          }
          .navigation {
            &.hidden {
              visibility: hidden;
            }
          }
        }
        &-south {
          h2 {
            font-size: 16px;
            font-weight: 600;
          }
          .description {
            font-size: 14px;
            font-weight: 400;
          }
          width: 100%;
          .ellipsis {
            display: block;
            margin-top: 20px;
            font-weight: bold;
            text-decoration: underline;
          }
        }
      }
    }
    &-content {
      &.isMenuDeleteOpen {
        margin-bottom: inherit;
      }
      overflow: visible;
      h2 {
        display: none;
      }
      margin: 0;
      padding: 55px 25px;
      .product-detail-header {
        display: none;
      }
      .product-detail-subheader {
        display: none;
      }
      .media-item {
        width: 100%;
        &--container {
          width: 100%;
        }
      }
    }
    &-action {
      display: none;
    }
    &-styles {
      .view {
        display: none;
      }
    }
  }
}

@include mq($until: mobile) {
  .product-detail-styles .media-item-header-content {
    .download {
      margin-left: 0px;
    }
  }
}
@include mq($until: tablet) {
  .product-detail-styles .media-item-header-content {
    .brush {
      margin-left: 15px;
    }
  }
}

@include mq($until: tablet-portrait) {
  .product-detail-intro-mobile-middle {
    h1 {
      width: max-content;
    }
  }
}

@include mq($from: tablet, $until: desktop) {
  .product-detail {
    &-intro {
      &-north {
        .image {
          width: 70%;
        }
      }
    }
  }
}
