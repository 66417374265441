.media-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;

  &-container {
    width: 100%;
    &-sub {
      .media-item-header {
        height: 58px;
      }
    }
  }

  &-header {
    display: flex;
    column-gap: 18px;
    &-control {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 7px;
      padding-bottom: 20px;
    }
    &-content {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 65px;
      color: $color-charcoal-grey;
      background-color: $color-white;
      box-shadow: 0px 4px 4px rgba(230, 230, 230, 0.25);
      border-radius: 1px 1px 0px 0px;
      margin-bottom: 7px;
      padding-right: 35px;
      &-sub {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 55px;
        color: $color-charcoal-grey;
        background-color: $color-white;
        box-shadow: 0px 4px 4px rgba(230, 230, 230, 0.25);
        border-radius: 1px 1px 0px 0px;
        margin-bottom: 7px;
        padding-right: 35px;
        .count-files {
          display: none;
        }
        .arrow {
          margin-left: 40px;
          width: 25px;
        }

        h4 {
          margin: 0px;
          margin-left: 35px;
          flex-grow: 1;
          font-size: 12px;
        }
        .details {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 40%;
          justify-content: space-between;
          p {
            font-size: 12px;
          }
          .download {
            &.hidden {
              visibility: hidden;
            }
          }
        }
      }

      .count-files {
        display: none;
      }
      .arrow {
        margin-left: 35px;
      }

      h4 {
        margin: 0px;
        margin-left: 35px;
        flex-grow: 1;
        font-size: 16px;
      }
      .details {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 40%;
        justify-content: space-between;
        p {
          font-size: 14px;
        }
        .download {
          &.hidden {
            visibility: hidden;
          }
        }
      }
    }
  }

  &-content {
    display: flex;
    column-gap: 18px;
    &-action {
      display: flex;
      align-items: center;
      padding-bottom: 20px;
    }
    &-detail {
      flex: 1;
      display: flex;
      font-size: 14px;
      background-color: $color-light-grey;
      box-shadow: 0px 4px 4px rgba(230, 230, 230, 0.25);
      z-index: 1;
      border-radius: 0px;
      height: 50px;
      margin-bottom: 4px;
      align-items: center;
      justify-content: space-between;
      padding-right: 35px;
      div {
        display: flex;
      }
      .viewIcon {
        cursor: pointer;
      }
      .document {
        margin-left: 40px;
      }
      .download {
        margin-left: 35px;
      }
      .elipsis {
        margin: 0px;
        margin-left: 35px;
        flex-grow: 1;
        line-height: 16px;
      }
      .link-vimeo-mobile {
        display: block;
      }
    }
  }

  button {
    display: flex;
    background-color: white;
    border: none;
    align-self: center;
  }
}

.modalDetail {
  outline: none;
  position: absolute;
  top: 75px;
  left: 75px;
  right: 75px;
  bottom: 75px;
  display: flex;
  flex-direction: column;
  color: $color-white;
  row-gap: 40px;
  &-north {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &-name {
      font-size: toEm(26px);
      font-weight: 700;
      line-height: 36px;
      display: flex;
      column-gap: 20px;
      flex: 1;
      align-items: center;
    }
    &-action {
      display: flex;
      align-items: center;
    }
    &-download {
      img.video {
        margin-right: 10px;
      }
      border: 1px solid $color-white;
      color: $color-white;
      text-transform: uppercase;
      text-decoration: none;
      padding: 10px 20px;
      border-radius: 4px;
    }
  }
  &-south {
    color: white;
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: row;
    max-height: 100%;
    &.picture {
      overflow: hidden;
      column-gap: 180px;
    }
    &-left,
    &-right {
      margin: 0 20px;
      img {
        cursor: pointer;
      }
    }
    &-middle {
      flex: 1;
      display: flex;
      justify-content: center;
      max-height: 100%;
      overflow: scroll;
      .react-pdf__Page {
        margin-bottom: 20px;
      }
      img {
        width: 100%;
        height: 100%;
        max-height: 100%;
        object-fit: cover;
        overflow: hidden;
      }
    }
  }
}
.modalDetail-overlay {
  z-index: 100000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
}

@include mq($until: mobile-sm) {
  .media-item-header-content {
    padding-right: 15px;
    .arrow {
      margin-left: 15px;
    }
    h4 {
      font-size: 12px;
    }
  }
}
@include mq($until: tablet-portrait) {
}

@include mq($until: tablet) {
  .media-item-content-detail {
    font-size: 12px;
    line-height: 17px;
    padding-right: 15px;
    .document {
      margin-left: 15px;
    }
  }
  .media-item-header-content {
    padding-right: 15px;
    .details {
      width: inherit;
    }
    h4 {
      margin-left: 15px;
      font-size: 14px;
    }
    .arrow {
      margin-left: 15px;
    }
  }
}
@include mq($until: tablet) {
  .viewIcon {
    display: none;
  }
  .media-item {
    &-header {
      .zip,
      .files {
        display: none;
      }
      &-control {
        display: none;
      }
      .count-files {
        display: inline;
      }
      h4 {
        font-weight: 600;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    &-content {
      &-action {
        display: none;
      }
    }
  }
}
