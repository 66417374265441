// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

.b-btn {
  width: fit-content;
  padding: 14px 30px;
  text-transform: uppercase;
  color: $color-white;
  margin: 0;
  border: none;
  border-radius: 4px;
  background-color: $color-primary-blue;
  font-weight: bold;
  font-size: 14px;
  &.secondary {
    background-color: $color-white;
    color: $color-primary-blue;
    &.withBorder {
      color: $color-primary-blue;
      border-radius: 4px;
      border: 1.5px solid $color-primary-blue;
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.2;
}

.arrow-btn {
  width: fit-content;
  text-transform: uppercase;
  background-color: $color-grayscale-gray-30;
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 6px;
  font-weight: bold;
  font-size: 10px;
  overflow: hidden;
}
.delete-btn {
  padding: 13px 30px;
  line-height: 20px;
  vertical-align: bottom;
  color: #bb0505 !important;
  border: 1.5px solid #bb0505;
  font-weight: 800;
  .logo {
    width: 30px;
    height: 20px;
  }
}

.small-btn {
  margin-left: 15px;
  background-color: $color-grayscale-gray-10;
}
.btn-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 30px;
  border: 1px solid red;
}

.text-btn-red {
  margin-left: 10px;
  color: red;
}
