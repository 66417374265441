.b-select2-container {
  position: relative;
  width: 100%;
  ul li {
    font-size: 14px;
  }

  .btn-select {
    border: none;
    background-color: white;
    font-weight: 600;
  }
  .select-open {
    width: 200px;
  }

  .dropdown-multi-select {
    position: absolute;
    @include mq($until: tablet) {
      right: 10px;
    }
    .row-item {
      display: flex;
      align-items: center;
    }
  }

  .b-input-wrapper {
    border: 1px solid $color-grayscale-gray-30;
    &.errored {
      border-color: $color-support-alert-50;
    }
  }

  label {
    display: block;
    margin-bottom: 8px;
    @include text-form();
    color: $color-grayscale-gray-90;
  }

  button {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    background: transparent;
    border: 1px $color-grayscale-gray-30 solid;
    padding: 12px 16px;
    margin: 0;
    column-gap: 12px;
    text-decoration: none;
    color: $color-grayscale-gray-90;
    border-radius: 4px;
    cursor: pointer;
    transition: background 250ms ease-in-out, transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 42px;
    width: 100%;
    @include text-form;

    .value {
      display: flex;
      align-items: center;
      column-gap: 4px;
    }

    &.errored {
      border-color: $color-support-alert-50;
    }
    &.disabled {
      background-color: $color-grayscale-gray-10;
      cursor: not-allowed;
    }

    .placeholder {
      font-size: 1em;
      color: $color-grayscale-gray-30;
    }

    .clear-icon {
      margin-right: 10px;
    }

    &:focus-within {
      border-color: $color-primary-primary-20;
    }
  }

  .b-select-caption {
    display: inline-block;
    color: $color-grayscale-gray-60;
    font-size: 0.8em;
    &.errored {
      color: $color-support-alert-50;
    }
  }

  ul {
    max-height: 300px;
    list-style: none;
    padding: 12px 16px;
    margin: 0;
    border: 1px $color-grayscale-gray-30 solid;
    border-radius: 4px;
    width: auto;
    position: absolute;
    z-index: 99999;
    background-color: white;
    top: 50px;
    min-width: auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    li {
      padding: 5px 0;
      display: flex;
      align-items: center;
      column-gap: 4px;
      &.highlighted {
        background-color: $color-grayscale-gray-10;
        margin-left: -16px;
        padding-left: 16px;
        margin-right: -16px;
        padding-right: 16px;
      }
    }

    &.closed {
      display: none;
    }
    &.has-label {
      top: 85px;
    }
    &.has-inside-pills {
      position: relative;
      top: 10px;
    }
  }

  .input-container {
    &.has-inside-pills {
      display: flex;
      flex-wrap: wrap;
      border: 1px $color-grayscale-gray-30 solid;
      border-radius: 4px;
      padding: 8px 16px;
      .b-input-wrapper {
        border: none;
      }
    }
    &:read-only {
      .b-input-wrapper {
        background-color: transparent;
      }
    }
  }

  .pills-wrapper {
    margin-top: 8px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 8px;
    row-gap: 8px;
    align-items: baseline;
  }
  .pills {
    display: flex;
    flex-wrap: wrap;
    column-gap: 8px;
    row-gap: 8px;
    align-items: baseline;
  }

  &.only-value {
    label {
      color: $color-grayscale-gray-70;
    }
    .input-container {
      &:not(.has-inside-pills) {
        display: none;
      }
      &.has-inside-pills {
        border: none;
        padding: 0;
        .b-input-container {
          display: none;
        }
      }
    }
  }
}
