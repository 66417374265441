.product-edit {
  background-color: $color-admin-gray;
  min-height: 100%;
  min-width: 1180px;

  .error {
    color: #bb0505;
    font-size: 13px;
    font-style: oblique;
  }

  &-content {
    margin: auto;
    padding-bottom: 150px;
    .custom-header {
      margin-top: inherit;
      padding: 40px 80px;
      box-shadow: 0 4px 4px 0px #5f4a4a14;

      a,
      div {
        color: $color-dark-grey;

        p {
          border-color: $color-dark-grey;
        }
      }
    }
    &-page {
      margin: 0 auto;
      width: 850px;
      margin-top: 30px;

      .back-button {
        position: absolute;
        top: 50px;
        display: flex;
        border: none;
        background: none;
        align-items: center;
        column-gap: 9px;
        p {
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 26px;
        }
      }
      h2 {
        font-size: 26px;
        margin-bottom: 20px;
      }
      &-form {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        legend {
          font-size: 12px;
        }
        .b-select2-container {
          button {
            font-size: 14px;
          }
          ul {
            li {
              font-size: 14px;
            }
          }
        }
        .notice {
          font-size: 11px;
        }
        .documentation-files-list-item.deleting {
          color: red;
          .download,
          .trash {
            visibility: hidden;
          }
        }
        .documentation-files-list-item span {
          font-size: 14px;
        }
        &-documentation,
        &-description {
          padding: 50px 107px;
          background-color: $color-white;
          box-shadow: 0px 4px 4px 0px #e5e5e540;
          &.isDisabled {
            opacity: 0.5;
          }
        }
        .product-info {
          &-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            &-left {
              h4 {
                font-size: 16px;
                font-weight: 600;
                line-height: 21px;
              }
              h5 {
                color: $color-medium-grey;
                font-weight: 400;
                line-height: 22px;
                font-size: 14px;
              }
              h4,
              h5 {
                margin: 0;
              }
            }
          }
          &-content {
            padding-top: 35px;
            .product-info-detail {
              row-gap: 32px;
            }
            .b-btn {
              margin-bottom: 30px;
            }
            form {
              display: flex;
              flex-direction: column;

              .field {
                width: 525px;
                &.small {
                  width: 310px;
                  .b-select2-container {
                    ul {
                      width: 310px;
                      top: 39px;
                      left: -15px;
                      color: $color-light-charcoal-grey;
                      font-weight: 600;
                    }
                  }
                }
                fieldset {
                  border-width: 2px;
                  border-color: $color-grey;
                  color: $color-medium-grey;
                  &.error {
                    color: $color-support-alert-50;
                    border: 1px solid $color-support-alert-50;
                    padding-left: 14px;
                  }
                }
                .error {
                  color: $color-support-alert-50;
                }
                textarea,
                input {
                  color: $color-light-charcoal-grey;
                  font-weight: 600;
                  width: 100%;
                  border: none;
                  font-size: 14px;
                  padding: 0 0 10px 0;
                }
              }
              button.submit {
                background-color: $color-primary-blue;
                padding: 15px 40px;
                color: $color-white;
                text-transform: uppercase;
                size: 14px;
                line-height: 26px;
                border-radius: 4px;
                align-self: flex-start;
                border: none;
                font-weight: 700;
                font-size: 14px;
                &.disabled {
                  opacity: 0.4;
                }
              }
              .notice {
                color: $color-medium-grey;
              }

              .upload-file-containers {
                display: flex;
                flex-direction: row;
                column-gap: 20px;
                .uploader {
                  width: 200px;
                  position: relative;
                  cursor: pointer;
                  .close-uploader {
                    &:hover {
                      background-color: rgba(255, 255, 255, 0.5);
                    }
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: flex-end;
                    padding: 18px;
                    img {
                      width: 11px;
                      height: 11px;
                    }
                  }
                  img.illu {
                    background-color: $color-charcoal-grey;
                  }

                  img {
                    object-fit: contain;
                    width: 200px;
                    height: 150px;
                  }
                  &-content {
                    border: 2px dashed #ececec;
                    display: flex;
                    align-items: center;
                    justify-items: center;
                    flex-direction: column;
                    text-align: center;
                    height: 152px;
                    justify-content: center;
                    img {
                      width: 35px;
                      height: 35px;
                    }
                    h4 {
                      text-transform: uppercase;
                    }
                    h4,
                    h5 {
                      font-size: 12px;
                      line-height: 16px;
                      margin: 0;
                    }
                  }
                }
              }

              .subfolder {
                margin-left: 30px;
                .submedia-folder {
                  margin: 20px 0px 0px 15px;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  row-gap: 15px;
                }
              }
              .edit-folder-name-container {
                .edit-folder-name-row {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  gap: 10px;
                  .field {
                    width: max-content !important;
                    display: flex !important;
                    justify-content: center !important;
                    padding: 5px !important;
                  }
                }
              }

              .documentation-files {
                display: flex;
                flex-direction: column;
                &-deleting-subFolder-folder {
                  color: red;
                  &-trash {
                    visibility: hidden;
                  }
                }
                &-title {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: space-between;
                  h4 {
                    font-size: 16px;
                  }

                  &-container {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 10px;
                  }

                  .arrow-container {
                    margin: 10px 10px 0 0;
                  }
                  &-sub {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    h4 {
                      font-size: 15px;
                    }
                    .arrow-name-container {
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      gap: 10px;
                      .arrow-container {
                        margin: 10px 10px 0px 0px;
                        img {
                          width: 25px;
                        }
                      }
                    }
                  }
                }
                .separator {
                  width: 25px;
                  margin: 0;
                  padding: 0;
                  border-bottom: 2px solid $color-dark-grey;
                }
                &-list {
                  margin-top: 20px;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  row-gap: 15px;
                  &-item {
                    display: flex;
                    border-bottom: 1px solid $color-grey;
                    column-gap: 35px;
                    padding-bottom: 13px;
                    span {
                      flex: 1;
                    }
                    &:last-child {
                      border-bottom: none;
                    }
                  }
                }
              }

              .b-select2-container button {
                padding: 0;
                border: none;
                height: fit-content;
              }

              button.video {
                align-self: flex-end;
              }
            }
            .separator {
              background-color: #ececec;
              height: 1px;
              width: 100%;
              margin-block: 20px;
            }
          }
        }
      }
    }
  }
  &-action {
    position: fixed;
    bottom: 0;
    padding: 32px 80px;
    display: flex;
    justify-content: space-between;
    background-color: white;
    width: 100%;
  }
}

.modalDelete-overlay {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  .modalDelete {
    background-color: white;
    flex-direction: column;
    height: fit-content;
    padding: 50px 100px;
    width: fit-content;
    text-align: center;
    align-items: center;
    display: flex;
    h4 {
      margin: 0;
    }
    p {
      margin: 0;
      font-size: 12px;
      margin-bottom: 20px;
    }
    img {
      width: 70px;
    }
  }
}

.modalFolder-overlay {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  .modalFolder {
    background-color: white;
    flex-direction: column;
    height: fit-content;
    padding: 50px 100px;
    width: fit-content;
    text-align: center;
    align-items: center;
    display: flex;

    .new-create {
      display: flex;
      align-items: center;
      &-bloc-img {
        background-color: #deebff;
        border: none;
        border-radius: 4px;
        font-weight: bold;
        padding: 6px;
        margin-right: 5px;
        img {
          width: 20px;
          font-weight: bold;
        }
      }
    }

    .separator {
      width: 100%;
      margin: 0;
      border-bottom: 2px solid $color-grey;
    }
    h4 {
      margin: 0;
    }
    p {
      margin: 0;
      font-size: 12px;
      margin-bottom: 20px;
    }
    img {
      width: 70px;
    }
    .select-container {
      display: flex;
      flex-direction: row;
      gap: 20px;
      margin-bottom: 20px;
      .small-fieldset {
        border-color: #ff000042;
      }
    }
    .field {
      width: 525px;
      text-align: left;
      &.small {
        width: 310px;
        .b-select2-container {
          ul {
            width: 310px;
            top: 39px;
            left: -15px;
            color: $color-light-charcoal-grey;
            font-weight: 600;
          }
        }
      }
      fieldset {
        border-width: 2px;
        border-color: $color-grey;
        color: $color-medium-grey;
        &.error {
          color: $color-support-alert-50;
          border: 1px solid $color-support-alert-50;
          padding-left: 14px;
        }
      }
      .error {
        color: $color-support-alert-50;
      }
      textarea,
      input {
        color: $color-light-charcoal-grey;
        font-weight: 600;
        width: 100%;
        border: none;
        font-size: 14px;
        padding: 0 0 10px 0;
      }
    }

    .container-drop-video {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: flex-start;
      gap: 10px;
      margin-bottom: 20px;
    }
    .dropzone-documentation {
      width: 100%;
      .loading-multiple {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #000000d6;
        color: white;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &.isDragAccept {
        border-color: $color-support-success;
        border-style: dashed;
      }
      &.isDragReject {
        border-color: $color-support-alert-50;
        border-style: dashed;
      }
      &.isDragActive {
        opacity: 0.5;
      }
      position: relative;
      display: flex;
      align-items: center;
      border: 2px solid $color-grey;
      flex-direction: column;
      padding: 60px;
      margin-bottom: 36px;
      .types-files {
        display: flex;
        column-gap: 16px;
        font-size: 12px;
        color: $color-dark-grey;
        margin-bottom: 29px;
        font-weight: 700;
      }
      h5,
      h4 {
        padding: 0;
        margin: 0;
      }
      h4 {
        margin-bottom: 15px;
      }
      h5 {
        color: $color-primary-blue;
        text-decoration: underline;
        font-size: 14px;
        font-weight: 700;
      }
    }

    .uploaded-file {
      box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 15px;
      padding: 5px;
      margin-bottom: 15px;
    }
  }
}
