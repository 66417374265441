.custom-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  width: 100%;
  color: $color-white;
  justify-content: space-between;
  font-weight: 700;
  &-left {
    margin-left: 10.4%;
    .back-button {
      display: flex;
      align-items: center;
      background: none;
      border: none;
      font-size: 14px;
      font-weight: bold;
      justify-content: space-between;
      column-gap: 5px;
      margin-top: 20px;
    }
  }
  &-right {
    font-size: 14px;
    column-gap: 25px;
    display: flex;
    align-items: center;
    .dropdown {
      position: relative;
      div {
        cursor: pointer;
      }
    }

    &-disable {
      opacity: 0.2;
      border-bottom: none !important;
    }


    .menu {
      position: absolute;
      right: 0;
      list-style-type: none;
      margin: 5px 0;
      padding: 0;
      z-index: 20;
      border-radius: 2px;
      background-color: white;
      width: 320px;

      .menu-item {
        display: flex;
        flex-direction: row;
        gap: 10px;
      }
    }

    .menu > li {
      margin: 0;
      p {
        margin: 0;
        padding: 0;
      }
      padding: 10px;
    }

    .menu > li:hover {
      background-color: lightgray;
      border-radius: inherit;
    }

    .menu > li > a {
      width: 100%;
      height: 100%;
      text-align: left;

      background: none;
      color: black;
      border: none;
      margin: 0;
      font: inherit;
      cursor: pointer;
    }
  }
  .back-button {
    justify-self: flex-start;
  }

  a {
    text-decoration: none;
    color: $color-white;

    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
      text-decoration: none;
    }
  }

  p {
    text-transform: uppercase;
    border-bottom: 2px transparent;
    line-height: 26px;

    &.current {
      font-weight: 700;
      border-bottom: 2px solid;
    }
  }

  .settings-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &--icon {
      border-radius: 4px;
      border: none;
      padding: 0;
      height: 45px;
      width: 45px;
      &.open {
        background: $color-white;
      }
      &.closed {
        background: #292929;
      }
    }

    &--menu {
      background-color: $color-white;
      border-radius: 4px;
      color: black;
      width: 300px;
      padding-left: 40px;
      margin-top: 55px;
      position: absolute;
      display: flex;
      flex-direction: column;
      row-gap: 19px;
      padding: 16px 40px;
      z-index: 9;

      &-option {
        cursor: pointer;
        display: flex;
        background-color: transparent;
        border: none;
        align-items: center;
        justify-content: flex-start;
        p {
          text-transform: none;
          margin: 0;
        }

        img {
          padding-right: 13px;
        }
      }
    }
  }
}
.custom-header-mobile {
  display: flex;
  color: $color-white;
  justify-content: space-between;
  width: 100%;
  margin: 25px;
  height: 43px;
  align-items: center;
  &-center {
    font-size: toEm(16);
    font-weight: 600;
  }
  &-right {
    background-color: rgba(0, 0, 0, 0.2);
    padding: 12px 10px;
    height: 100%;
    align-items: center;
    display: flex;
    border-radius: 4px;
    &-light {
      background-color: white;
      color: black;
      padding: 12px 10px;
      height: 100%;
      align-items: center;
      display: flex;
    }
  }
  &-left {
    display: flex;
    flex-direction: column;
    height: 100px;
    margin-top: 70px;
    gap: 25px;
    .back-button {
      display: flex;
      align-items: center;
      background: none;
      border: none;
      font-size: 14px;
      font-weight: bold;
      justify-content: space-between;
      column-gap: 5px;
    }
  }
}

.menu-mobile-content {
  z-index: 1;
  display: none;
  background-color: $color-white;
  width: 100vw;
  height: 100vh;
  position: fixed;
  flex-direction: column;
  padding: 50px;
  &-header {
    justify-content: flex-end;
    display: flex;
    margin-bottom: 40px;
  }
  &-south {
    &-item {
      display: flex;
      height: 70px;
      align-items: center;
      &.current a {
        padding-bottom: 5px;
        p {
          border-bottom: 1px solid $color-charcoal-grey;
        }
      }
      a {
        align-items: center;
        display: flex;
        text-decoration: none;
        color: $color-charcoal-grey;
        p {
          font-size: toEm(16);
          margin: 0;
        }
        img {
          margin-right: 20px;
          height: 20px;
        }
      }
      img {
        margin-right: 20px;
        height: 20px;
      }
    }
  }
}

@include mq($until: tablet) {
  .custom-header {
    display: none;
  }
  .menu-mobile-content,
  .custom-header-mobile {
    z-index: 2;
    display: flex;
    &-left {
      .logo {
        width: 43px;
      }
    }
  }

  .menu-mobile-content {
    &-south {
      display: flex;
      row-gap: 34px;
      flex-direction: column;
      &-item {
        align-items: flex-start;
        font-size: 16px;
        color: $color-charcoal-grey;
        font-weight: 600;
        height: inherit;
        a {
          width: 100%;
        }
        .support-button-mobile {
          display: flex;
          flex-direction: row;
        }
        .support-group-mobile {
          display: flex;
          flex-direction: column;
          margin-left: 20px;
          gap: 15px;
          margin-top: 20px;
          .current {
            padding-bottom: 5px;
            p {
              border-bottom: 1px solid $color-charcoal-grey;
            }
          }
        }
      }
      p {
        padding: 0;
      }
      .support-group-item {
        display: flex;
        flex-direction: column;
      }
    }
  }
  .product-edit {
    .custom-header {
      display: flex;
    }
    .menu-mobile-content,
    .custom-header-mobile {
      display: none;
    }
  }
}

@include mq($from: tablet) {
  .custom-header-mobile {
    display: none;
  }
}
