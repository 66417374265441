.training {
  background: linear-gradient(360deg, #071463 0%, #14459b 51.2%);
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  &-header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    button {
      &.closed {
        background: $color-dark-blue !important;
      }
    }
  }
  &-intro {
    @include sidebar();
    &-north {
      img {
        width: 200px;
      }
    }
    &-south {
      justify-content: center;
      display: flex;
      flex-direction: column;
      flex: 1;
      p {
        width: 300px;
        opacity: 0.7;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
  &-content {
    margin-right: 80px;
    padding-bottom: 70px;
    width: 100%;
    display: flex;
    flex-direction: column;

    &-middle {
      min-height: 480px;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $color-white;

      .image-content {
        width: 30%;
        max-width: 600px;
        min-width: 250px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        svg {
          position: absolute;
          transform: scale(1.8);
        }
        .circle-big {
          stroke-width: 0.15;
          stroke-linecap: square;
          stroke: #ffffff;
          fill: none;
          transform: rotate(250deg);
          transform-origin: 50% 50%;
        }
        .circle-small {
          fill: none;
          stroke-width: 0.1;
          stroke-linecap: square;
          stroke: #ffff;
          transform: scale(0.9) rotate(75deg);
          transform-origin: 50% 50%;
          opacity: 0.35;
        }
        &-control {
          display: flex;
          flex-direction: column;
          align-items: center;
          z-index: 1;
          cursor: pointer;
          h2 {
            font-size: 26px;
            font-weight: bold;
            margin-bottom: 15px;
            margin-top: 6%;
          }
          img {
            width: 85%;
          }
          p {
            font-size: 14px;
            text-align: center;
            margin: 0;
            &.subtitle {
              font-size: 26px;
              font-weight: bold;
            }
          }
        }
      }
    }

    button.join {
      z-index: 1;
      width: 244px;
      margin: 35px;
      height: 50px;
      background: #ffffff;
      border-radius: 4px;
      color: $color-primary-blue;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      border: none;
      align-self: center;
      text-transform: uppercase;
    }
  }
}

@include mq($until: tablet) {
  .training {
    overflow: hidden;
    &-header {
      justify-content: unset;
    }
    &-intro {
      display: none;
    }
    &-content {
      margin-right: 0;
      &-middle {
        flex: none;
        height: 55%;
        .image-content {
          width: 70%;
          svg {
            position: absolute;
            transform: scale(2);
            top: -50%;
          }
          .circle-big {
            transform: rotate(180deg);
          }
          .circle-small {
            transform: scale(0.96) rotate(90deg);
          }
          &-control {
            .description {
              display: none;
            }
            img {
              width: 60%;
            }
          }
        }
      }
      button.join {
        font-size: 14px;
        column-gap: 10px;
        padding: 0 18px;
        margin-bottom: 40px;
      }
    }
  }
}

@include mq($from: desktop) {
  .training-content-middle {
    .image-content {
      width: 28%;
    }
    img {
      max-height: 150px;
    }
  }
}
@include mq($from: desktop-large) {
  .training-content-middle {
    .image-content {
      width: 24%;
    }
    img {
      max-height: 150px;
    }
  }
}

@include mq($from: desktop-xlarge) {
  .training-content-middle {
    .image-content {
      width: 25%;
    }

    img {
      max-height: 200px;
    }
  }
}
