// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------
@mixin animateCircle {
  0% {
    transform-origin: center;
    transform: rotate3d(0, 0, 1, 0deg) scale(1.8);
    opacity: 0;
  }
  100% {
    transform-origin: center;
    transform: rotate3d(0, 0, 1, 360deg)scale(1.8);
    opacity: 1;
  }
}

@mixin gradientPanelLeft {
  background: linear-gradient(360deg, #0C0C0C 0%, #353434 51.2%);
}

.pointer {
  cursor: pointer;
}
