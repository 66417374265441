.product-edit,
.product-detail,
.training,
.add-user,
.search {
  .search {
    background-color: white;
    input {
      color: $color-dark-grey;
    }
  }
}
.dashboard {
  .search {
    background-color: $color-dark-grey;
  }
}

.training {
  .search {
    background-color: $color-dark-blue;
    .search-btn,
    .search-input {
      background-color: $color-dark-blue;
      color: white;
    }
  }
}

.search {
  height: 45px;
  border-radius: 4px;
}

.search-input {
  outline: none;
  border: none;
  background: none;
  width: 0;
  padding: 0;
  color: #fff;
  float: left;
  font-size: 16px;
  transition: 0.3s;
  line-height: 40px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.search-btn {
  color: #fff;
  float: right;
  width: 45px;
  height: 45px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: 0.3s;
  .search-logo {
    cursor: pointer;
  }
}
.search-input:focus,
.search-input:not(:placeholder-shown) {
  width: 160px;
  padding: 0 10px;
}
.search:hover > .search-input {
  width: 160px;
  padding: 0 10px;
  cursor: pointer;
}

.add-user {
  .search {
    background-color: white;
  }
  .search-input {
    color: $color-dark-grey;;
  }
}

.list-users {
  .search {
    background-color: white;
  }
  .search-input {
    color: $color-dark-grey;;
  }
  .search-bar {
    background-color: white;
    height: 45px;
    border-radius: 4px;
    @include mq($until: tablet) {
      position: relative;
    }
    input {
      color: $color-dark-grey;
    }
  }
  .search-bar-input {
    cursor: text;
    outline: none;
    border: none;
    background: none;
    width: 160px;
    padding: 0 10px;
    color: #fff;
    float: left;
    font-size: 16px;
    transition: 0.3s;
    line-height: 40px;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }
  .search-bar-btn {
    color: #fff;
    float: right;
    width: 45px;
    height: 45px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: 0.3s;
    @include mq($until: tablet) {
      background-color: white;
      height: 56px;
    }
  }
  @include mq($until: tablet){
    .search-bar {
      height: 55px;
    }

    .search-bar-btn {
      padding: 4px 27px 0;
    }
    .search-bar-input {
      width: 0;
      padding: 0;
    }
    .search-bar > .search-bar-input {
      cursor: pointer;
    }
  }
}
