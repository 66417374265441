.support {
  background-color: #f6f6f6;
  min-height: 100vh;
  height: auto;
  display: flex;
  flex-direction: row;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  &-header {
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .custom-header a,
    p {
      color: $color-charcoal-grey !important;
    }
  }
  &-intro {
    width: 200px;
    height: 100%;
    background-color: inherit;
    position: relative;
    padding: 60px 60px;
    display: flex;
    flex-direction: column;
    &-north {
      img {
        width: 66px;
      }
    }
  }
  &-content {
    font-size: 14px;
    margin-right: 80px;
    padding-bottom: 70px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .separator {
      width: 100%;
      margin-bottom: 10px;
      padding: 0;
      border-bottom: 1px solid $color-grey;
    }
    .medium-text {
      font-family: Open Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-block: 6px;
    }
    .support-body {
      max-width: 1000px;
      width: 80%;
      margin-block: 20px;
      .top-part {
        h1,
        h2 {
          margin-bottom: 10px;
          color: $color-dark-grey;
        }
        .search-bar {
          position: relative;
          margin: 0;
          padding: 0;
          .search-input-faq {
            display: inline-block;
            border: none;
            line-height: 40px;
            color: $color-charcoal-grey;
            width: 100%;
            padding-inline: 10px;
            background: url('./../../assets/icons/search-black.svg') no-repeat
              right $color-grey;
            background-position: right 10px top 7px;
            margin-block: 20px;
          }
          .clear-btn {
            position: absolute;
            top: 30px;
            right: 10px;
            background-color: $color-grey;
            cursor: pointer;
          }
        }
      }
      .request-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
      .search-article {
        position: relative;
        .dropdown-search {
          position: absolute;
          list-style-type: none;
          margin: 5px 0;
          padding: 15px 10px;
          background-color: white;
          border: none;
          width: 100%;
          top: 55px;
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          .article-search-title {
            cursor: pointer;
            padding-block: 5px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            &:hover {
              background-color: $color-grey;
              margin-inline: -15px;
              padding-inline: 15px;
            }
          }
        }
      }
      .title-article {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        h3 {
          width: 60%;
          color: $color-dark-grey;
        }
        .search-article {
          width: 40%;
        }
      }

      .loader-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100px;
      }
      .article-content {
        margin-bottom: 30px;
        line-height: 30px;
      }

      .block-container {
        .text-info {
          font-family: Open Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
          margin-bottom: 20px;
        }
        .block {
          border: 1px solid $color-medium-grey;
          margin-bottom: 20px;
          padding: 20px 32px;
          cursor: pointer;
          .categorie-layout {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }

          .categorie-title {
            text-transform: uppercase;
            color: $color-dark-blue;
            font-family: Open Sans;
            font-size: 16px;
            font-weight: bolder;
            line-height: 26px; /* 162.5% */
            margin-top: 0px;
            margin-bottom: 6px;
          }
          .categorie-article {
            font-family: Open Sans;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 0px;
          }
        }
        .block:hover .arrow-img {
          visibility: visible;
        }
        .block .arrow-img {
          visibility: hidden;
        }
        .container-article {
          margin-top: 20px;
          .article-cta {
            padding: 5px;
            margin-block: 3px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
          }
          .article-cta:hover {
            background-color: $color-grey;
            cursor: pointer;
            font-weight: bold;
          }
        }
      }
      .card-notfound {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: $color-grey;
        padding: 32px;
        gap: 20px;
      }
      .button-notfound {
        background-color: white;
        padding: 10px 24px;
        font-family: Open Sans;
        font-size: 14px;
        font-weight: 900;
        line-height: 26px; /* 185.714% */
        text-transform: uppercase;
        color: $color-primary-blue;
        border-radius: 4px;
        border: 1.5px solid $color-primary-blue;
      }
      .button-notfound-faq {
        background-color: white;
        padding: 10px 24px;
        font-family: Open Sans;
        font-size: 14px;
        font-weight: 900;
        line-height: 26px; /* 185.714% */
        text-transform: uppercase;
        color: $color-primary-blue;
        border-radius: 4px;
        border: 1.5px solid $color-primary-blue;
      }
    }
  }

  .blurry-background {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: $color-medium-grey;
    opacity: 0.7;
    z-index: 42;
  }
  .sidebar-container {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 50;
    width: 600px;
    background-color: white;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
    &-open {
      transform: translate(0);
    }
    &-close {
      transform: translate(600px);
    }

    .form-wrapper {
      padding: 20px 50px;
      min-height: 100%;
      height: 100vh;
      h2 {
        font-size: 18px;
        text-transform: uppercase;
      }
      .error {
        color: $color-support-alert-50;
      }
      form {
        display: flex;
        flex-direction: column;
        row-gap: 32px;
        .device-type-label {
          color: $color-medium-grey;
        }
        .field {
          width: 100%;
          border-width: 2px;
          border-color: $color-grey;
          color: $color-medium-grey;
          &.small {
            width: 310px;

            .b-select2-container {
              ul {
                width: 310px;
                top: 39px;
                left: -15px;
                color: $color-light-charcoal-grey;
                font-weight: 600;
              }
            }
          }

          &.error {
            color: $color-support-alert-50;
            border: 1px solid $color-support-alert-50;
            padding-left: 14px;
          }

          .error {
            color: $color-support-alert-50;
          }
          textarea,
          input {
            color: $color-light-charcoal-grey;
            font-weight: 600;
            width: 100%;
            border: none;
            font-size: 14px;
            padding: 0 0 10px 0;
            &.disabled {
              color: black;
              cursor: grab;
            }
          }
        }

        .container-module-name {
          display: grid;
          grid-template-columns: 50% 50%;
          .b-checkbox-container {
            margin-bottom: 10px;
          }
        }
        .sidebar-cta-container {
          display: flex;
          gap: 30px;
          margin-bottom: 20px;
        }
      }
    }
    .sidebar-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
  .preview-file-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 1;
    gap: 20px;
    margin-top: 20px;
    .preview-card {
      width: 140px;
      height: 120px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      border: 1.5px solid var(--light-grey, #ececec);
      cursor: pointer;
      background-color: $color-dark-white;
      border-radius: 4px;
      padding: 10px;
      .preview {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-top: 5px;
        width: 100%;
        height: 100%;
        p {
          font-size: 15px;
          font-weight: 800;
        }
      }
      p {
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        text-align: center;
        max-width: 100%;
      }
    }
    .delete-logo {
      position: absolute;
      top: 0;
      right: 0;
      padding: 3px;
      cursor: pointer;
    }
    .title-container {
      background-color: white;
      width: 100%;
      padding-inline: 5px;
      position: absolute;
      bottom: 0;
      p {
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        text-align: center;
        vertical-align: middle;
        max-width: 90%;
        padding: 2%;
      }
    }

    .preview-card-comment {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      border: 1px $color-grey solid;
      background-color: $color-dark-white;
      border-radius: 4px;
      width: 100px;
      height: 120px;
      margin-top: 20px;
      cursor: pointer;
      h2 {
        font-size: 12px;
      }
      p {
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        text-align: center;
        max-width: 90px;
      }
    }
    .preview-card .delete-logo {
      visibility: hidden;
    }
    .preview-card-comment .delete-logo {
      visibility: hidden;
    }
    .preview-card:hover .delete-logo {
      visibility: visible;
    }
    .preview-card-comment:hover .delete-logo {
      visibility: visible;
    }
  }
}
.block-scroll {
  height: 100%;
  width: 100%;
  overflow-y: hidden;
  position: fixed;
}

.dropzone-documentation {
  .loading-multiple {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000000d6;
    color: white;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  &.isDragAccept {
    border-color: $color-support-success;
    border-style: dashed;
  }
  &.isDragReject {
    border-color: $color-support-alert-50;
    border-style: dashed;
  }
  &.isDragActive {
    opacity: 0.5;
  }
  position: relative;
  display: flex;
  align-items: center;
  border: 2px dashed $color-grey;
  flex-direction: column;
  padding: 20px;
  margin-bottom: 36px;
  .types-files {
    display: flex;
    column-gap: 16px;
    font-size: 12px;
    color: $color-dark-grey;
    margin-bottom: 29px;
    font-weight: 700;
  }
  h5,
  h4 {
    padding: 0;
    margin: 0;
  }
  h4 {
    margin-bottom: 15px;
  }
  h5 {
    color: $color-primary-blue;
    text-decoration: underline;
    font-size: 14px;
    font-weight: 700;
  }
}

.empty-request {
  display: flex;
  justify-content: center;
  font-size: 15px;
}
.table-request {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  th,
  td {
    padding: 10px;
    text-align: left;
  }
  .row-request {
    cursor: pointer;
  }

  .hidden-overflow {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100px;
    display: block;
  }
  .sorted-icn {
    margin-left: 8px;
  }
  .dsc-icn {
    vertical-align: baseline;
  }
  .asc-icn {
    vertical-align: super;
  }

  td,
  div {
    max-width: 140px;
    white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis;
    font-size: 14px;
    &.small {
      width: 70px;
      text-align: center;
      font-size: 14px;

      .cta {
        max-width: 40px;
        cursor: pointer;
      }
    }
    .block {
      width: 50px;
      height: 30px;
      background-color: rgba(20, 69, 155, 0.2);
      border-radius: 4px;
      padding: 4px 10px;
      font-weight: 500;
    }
  }

  th {
    background-color: #ffffff;
  }

  tr {
    background-color: rgba(255, 255, 255, 0.4);
    margin-bottom: 30px;
  }

  tbody tr:hover {
    background-color: #e0e0e0;
  }

  .selected {
    background-color: $color-admin-gray !important;
  }
}
.status {
  border-radius: 5px;
  width: fit-content;
  padding: 2px 8px;
  font-weight: 700;
  overflow: hidden;
  margin-top: 10px;
}
.en-cours,
.on-standby {
  background-color: rgba(170, 140, 105, 0.3);
  color: #aa8c69;
}
.waiting-for-customer {
  background-color: rgba(187, 5, 5, 0.3);
  color: #bb0505;
}
.waiting-for-support,
.in-repair {
  background-color: rgba(20, 69, 155, 0.3);
  color: #14459b;
}

.repaired {
  background-color: rgba(105, 170, 108, 0.2);
  color: #69aa6c;
}

.fermée {
  background-color: rgba(238, 206, 93, 0.3);
  color: #d1a60b;
}
.avatar-jira {
  width: 24px;
  height: 24px;
  border-radius: 14px;
  p {
    align-self: center;
    text-align: center;
    font-size: 10px;
    font-weight: bold;
    color: white;
  }
  &.user {
    background-color: #d1a60b;
  }
  &.assignee {
    background-color: $color-dark-blue;
  }
}

.bottom-part {
  background-color: white;
  padding: 40px;
  margin-block: 30px;
  display: flex;
  flex-direction: row;
  gap: 40px;
  h2 {
    margin: 0;
  }
  .left-part {
    width: calc(100% - 280px);
    .desc-container {
      margin-bottom: 30px;
    }
    .comment-container {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-bottom: 30px;
      .activity-text {
        text-align: right;
        color: $color-charcoal-grey;
        font-family: Open Sans;
        font-size: 14px;
        font-weight: 600;
      }
      .icon {
        width: 17px;
        cursor: pointer;
      }
      .edit {
        width: 14px;
      }
      .info-comment-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 15px;
        color: $color-charcoal-grey;
        font-family: Open Sans;
        font-size: 12px;
        width: 100%;
        .row {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;
        }
      }
      .user {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      .assignee {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
      span {
        font-size: 14px;
        font-family: Open Sans;
      }
      .comment-body {
        margin-top: 10px;
      }

      .field-container {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: center;
        position: relative;
        width: 100%;
        gap: 20px;
        margin-top: 15px;
        .dropzone-documentation {
          width: 20%;
          height: 100px;
          margin-bottom: 0;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          .loading-multiple {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
          }
        }

        .field {
          width: 80%;
          border-radius: 5px;
          border: 2px $color-grey solid;

          textarea {
            display: block;
            color: $color-light-charcoal-grey;
            font-weight: 600;
            width: 100%;
            height: 70px;
            border: none;
            font-size: 14px;
            padding: 0 0 10px 0;
            &.disabled {
              color: black;
              cursor: grab;
            }
          }
          &.edit-comment {
            border: 2px $color-grey solid;
            border-radius: 5px;
          }
        }
      }
      .cta-edit {
        color: $color-primary-blue;
        margin-top: 10px;
        cursor: pointer;
        bottom: -25px;
        right: 120px;
        text-align: right;
      }
      button {
        align-self: flex-end;
      }
    }
    .comment-global {
      gap: 15px;
    }

    .comment-global .actions {
      visibility: hidden;
    }
    .comment-global:hover .actions {
      visibility: visible;
    }
    .category-title {
      font-size: 15px;
      color: $color-charcoal-grey;
      margin-bottom: 20px;
    }
    .container-attachment {
      margin-bottom: 20px;
    }
    .center-loading {
      width: 100%;
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .more-detail-container {
      width: 70%;
      height: fit-content;
      margin-block: 20px;
      .info-detail {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 20px;
        width: 100%;
        span {
          color: black;
          font-weight: 900;
          width: 50%;
        }
        p {
          width: 50%;
          text-align: right;
        }
      }
    }
    .button-more {
      border: none;
      background-color: inherit;
      color: $color-primary-blue;
      font-weight: 900;
      font-size: 14px;
      font-family: Open Sans;
      padding-left: 0;
      margin-top: 20px;
    }
  }

  .right-part {
    border: 2px solid $color-admin-gray;
    width: 320px;
    padding: 24px 25px;
    gap: 12px;
    height: 200px;
    display: flex;
    flex-direction: column;
    .priority {
      border-radius: 5px;
      width: fit-content;
      padding: 2px 8px;
      font-weight: 700;
      overflow: hidden;
      margin-bottom: 10px;
    }
    .info-ticket {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      span {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        font-weight: bold;
        margin: 0;
        font-size: 13px;
      }
    }
    .high {
      background-color: rgba(187, 5, 5, 0.3);
      color: #bb0505;
    }
    .medium {
      background-color: rgba(238, 206, 93, 0.3);
      color: #d1a60b;
    }

    .low {
      background-color: rgba(105, 170, 108, 0.2);
      color: #69aa6c;
    }
  }
}

@include mq($until: tablet) {
  .support {
    padding: unset;
    &-intro {
      display: none;
    }

    .support-content {
      top: 0;
      margin-right: unset;
      background-color: white;

      .medium-text {
        font-size: 15px;
      }
      .small-mobile {
        display: block;
        width: 90px;
      }
      .support-body {
        flex: 1;
        width: 90%;
        margin-block: 0px;
        .hide-mobile {
          display: none;
          width: 0px;
          h1 {
            color: $color-dark-grey;
          }
        }
        .dropdown-search {
          border: 0.5px $color-dark-grey solid;
          border-top: none;
        }

        .title-article {
          margin-top: 40px;
          color: $color-dark-grey;

          h3 {
            width: 100%;
            font-size: 28px;
            line-height: 38px;
            font-weight: '900';
            margin-bottom: 20px;
            color: $color-dark-grey;
          }
        }
        .button-notfound {
          background-color: #14459b;
          width: 70px;
        }
        .button-show-more {
          border: 0.2px $color-grey solid;
        }
        .top-part {
          h1 {
            margin-top: 0;
            margin-bottom: 10px;
            color: $color-dark-grey;
          }
          margin-top: 30px;
          margin-bottom: 0;
        }

        .bottom-part {
          padding: 10px;
          margin-block: 30px;
          display: flex;
          flex-direction: column-reverse;
          gap: 40px;
          h2 {
            margin-bottom: 0;
          }
          .preview-card .delete-logo {
            visibility: visible;
          }
          .preview-card-comment .delete-logo {
            visibility: visible;
          }

          .left-part {
            width: 100%;
            .more-detail-container {
              width: 100%;
            }
            .activity-text {
              text-align: left;
            }

            .button-more {
              margin-bottom: 40px;
            }
          }
        }
      }
    }
    .table-request {
      tbody {
        width: 100%;
      }
      .more-info-row-mobile {
        width: 100%;
        background-color: $color-grey;
        td:first-child {
          font-weight: bold;
        }
        .btn-row-mobile {
          border: 1px blue solid;
        }
      }
    }
    .sidebar-container {
      width: 100%;
      .form-wrapper {
        padding: 20px 30px;
        form {
          .field {
            width: 100%;
          }
        }
      }
    }
  }
}
