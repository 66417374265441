.public-login {
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  background-image: -webkit-image-set(
      url('../../../assets/images/background-image.png') 1x,
      url('../../../assets/images/background-image@2x.png') 2x,
      url('../../../assets/images/background-image@3x.png') 3x
    ),
    linear-gradient(360deg, #071463 0%, #14459b 51.2%);
  background-blend-mode: multiply;
  //background-size: 100%;
  //background-repeat: repeat-y;
  flex-direction: column;
  color: $color-white;
  align-items: center;

  p {
    font-size: toEm(72px);
  }

  .logo {
    margin-top: 0%;
    width: 22%;
  }

  .users {
    margin: 0;
    margin-top: 50px;
    font-weight: 400;
    font-size: 3rem;
  }

  .title {
    font-weight: bold;
    font-size: 4.5rem;
    letter-spacing: 0.01em;
    margin-top: 7%;
    margin-bottom: 4%;
  }

  .subtitle {
    max-width: 800px;
    text-align: center;
    font-size: 20px;
    line-height: 28px;
  }

  .circle-content {
    display: block;
    margin: 10px auto;
    max-width: 170px;
    max-height: 170px;
    cursor: pointer;
  }

  .circle-big {
    stroke-width: 0.35;
    stroke-linecap: square;
    stroke: #ffffff;
    fill: rgba(7, 17, 79, 0.4);
    transform: rotate(200deg);
    transform-origin: 50% 50%;
  }

  .circle-small {
    fill: none;
    stroke-width: 0.3;
    stroke-linecap: square;
    stroke: #ffff;
    fill: rgba(7, 17, 79, 0.4);
    transform: scale(0.85) rotate(20deg);
    transform-origin: 50% 50%;
    opacity: 0.35;
  }

  .text {
    font-style: normal;
    font-weight: 700;
    font-size: 0.24em;
    text-transform: uppercase;
    fill: #ffffff;
    text-anchor: middle;
  }
}

@include mq(mobile-sm, tablet-portrait) {
  .public-login {
    row-gap: 1.1em;
    justify-content: center;

    .users {
      margin: 0;
      margin-top: 50px;
      font-weight: 400;
      font-size: 2rem;
      line-height: 30px;
      text-align: center;
      width: 100%;
    }

    & .logo {
      width: 50%;
    }
    & .subtitle {
      font-size: 1.5em;
    }
  }
}

@include mq($until: tablet-portrait) {
  .public-login {
    .users {
      margin: 0;
      margin-top: 50px;
      font-weight: 400;
      font-size: 2rem;
      line-height: 30px;
      text-align: center;
      width: 100%;
    }
    & .subtitle {
      width: inherit;
    }
  }
}

@include mq($until: tablet-portrait) {
  .public-login {
    .title {
      font-size: 2em;
    }
    .users {
      margin: 0;
      margin-top: 50px;
      font-weight: 400;
      font-size: 2rem;
      line-height: 30px;
      text-align: center;
      width: 100%;
    }
    .circle-content {
      max-width: 100px;
    }
    & .subtitle {
      width: 70%;
      line-height: 1.2em;
      font-size: 1em;
    }
  }
}
