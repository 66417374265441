$color-grayscale-gray-100: rgba(5, 5, 5, 1);
$color-grayscale-gray-90: rgba(29, 31, 34, 1);
$color-grayscale-gray-80: rgba(82, 88, 98, 1);
$color-grayscale-gray-70: rgba(120, 129, 144, 1);
$color-grayscale-gray-60: rgba(162, 172, 191, 1);
$color-grayscale-gray-30: rgba(201, 207, 218, 1);
$color-grayscale-gray-10: rgba(237, 240, 248, 1);
$color-grayscale-gray-05: rgba(248, 250, 255, 1);
$color-grayscale-gray-03: rgba(250, 250, 251, 1);
$color-white: #ffffff;
$color-dark-white: #f6f6f6;
$color-light-grey: #fafafa;
$color-grey: #ececec;
$color-medium-grey: #8c8b8b;
$color-charcoal-grey: #353434;
$color-light-charcoal-grey: #656565;
$color-dark-grey: #292929;
$color-primary-blue: #14459b;
$color-dark-blue: #0e2c7e;
$color-admin-gray: #f6f6f6;

$colors: (
  'grayscale-gray-100': $color-grayscale-gray-100,
  'grayscale-gray-90': $color-grayscale-gray-90,
  'grayscale-gray-80': $color-grayscale-gray-80,
  'grayscale-gray-70': $color-grayscale-gray-70,
  'grayscale-gray-60': $color-grayscale-gray-60,
  'grayscale-gray-30': $color-grayscale-gray-30,
  'grayscale-gray-10': $color-grayscale-gray-10,
  'grayscale-gray-05': $color-grayscale-gray-05,
  'grayscale-gray-03': $color-grayscale-gray-03,
  'white': $color-white,
  'dark-whitte': $color-dark-white,
  'grey': $color-grey,
  'light-grey': $color-light-grey,
  'medium-grey': $color-medium-grey,
  'charcoal-greyy': $color-charcoal-grey,
  'dark-grey': $color-dark-grey,
  'primary-blue': $color-primary-blue,
  'dark-blue': $color-dark-blue,
);

$color-primary-primary-0: rgba(243, 247, 255, 1);
$color-support-alert-50: rgba(191, 52, 33, 1);
$color-support-success: rgb(32, 200, 45);
$color-primary-primary-20: rgba(150, 187, 241, 1);
