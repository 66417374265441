$checkbox-width: 20px;

.b-checkbox-container {
  display: inline-flex;
  align-items: center;

  // On commence par cacher le checkbox
  .b-i-checkbox:checked,
  .b-i-checkbox:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  // Etat initial (de base)
  // juste le label
  .b-i-checkbox:checked + label,
  .b-i-checkbox:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: $color-grayscale-gray-90;

    &:hover {
      &::before {
        border-color: $color-charcoal-grey;
        background-color: $color-primary-primary-0;
      }
    }
    &.errored {
      &::before {
        border-color: $color-support-alert-50;
      }
      &::after {
        background-color: $color-support-alert-50;
      }
    }
    &.disabled {
      cursor: not-allowed;
      color: $color-grayscale-gray-30;
      &::before {
        border-color: $color-grayscale-gray-10;
        background-color: $color-grayscale-gray-05;
      }
    }
    &.indeterminate {
      &::before {
        border-color: $color-charcoal-grey;
        transform: scale(1);
        opacity: 1;
      }
      &::after {
        transform: scale(1);
        opacity: 1;
        width: 12px;
        height: 1px;
        top: 10px;
        left: 4px;
        background-image: url('../../../app/components/Form/Checkbox/indeterminate.svg');
      }

      &.disabled {
        &::before {
          border-color: $color-grayscale-gray-10;
          transform: scale(1);
          opacity: 1;
        }
        &::after {
          background-color: $color-grayscale-gray-10;
          background-image: url('../../../app/components/Form/Checkbox/indeterminate-disabled.svg');
        }
      }
    }
  }

  // Le bouton checkbox
  // Etat de base
  .b-i-checkbox:checked + label:before,
  .b-i-checkbox:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 1px solid $color-grayscale-gray-60;
    background: #fff;
    border-radius: 4px;
  }

  // l'intérieur
  .b-i-checkbox:checked + label:after,
  .b-i-checkbox:not(:checked) + label:after {
    content: '';
    background-image: url('../../../app/components/Form/Checkbox/check.svg');
    background-size: $checkbox-width $checkbox-width;
    background-color: $color-charcoal-grey;
    border-radius: 4px;
    height: $checkbox-width;
    width: $checkbox-width;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  .b-i-checkbox:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  .b-i-checkbox:checked + label:after {
    opacity: 1;
    transform: scale(1);
    height: 20px;
    width: 20px;
    top: 0px;
    left: 0px;
  }
  .b-i-checkbox:checked.disabled + label:after {
    background-color: $color-grayscale-gray-10;
    background-image: url('../../../app/components/Form/Checkbox/check-disabled.svg');
  }
  .b-i-checkbox:checked.disabled + label:before {
    background: white;
  }
  .b-i-checkbox:checked + label:before {
    border-color: $color-charcoal-grey;
  }

  .b-checkbox-label {
    display: inline-block;
    font-size: 0.9em;
    margin-left: 5px;
    cursor: pointer;
  }
}
