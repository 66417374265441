.toggle-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  font-size: 16px;
  font-weight: 600;
}
.switch {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $color-medium-grey;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.toggle-slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: -2px;
  bottom: -3px;
  background-color: $color-dark-white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .toggle-slider {
  background-color: $color-grey;
}
input:checked + .toggle-slider:before {
  background-color: $color-primary-blue;
}
input:focus + .toggle-slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .toggle-slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded sliders */
.toggle-slider.round {
  border-radius: 34px;
}

.toggle-slider.round:before {
  border-radius: 50%;
}
