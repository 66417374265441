.search {
  background-color: #f6f6f6;
  min-height: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  &-header {
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .custom-header a,
    p {
      color: $color-charcoal-grey !important;
    }
  }
  &-intro {
    width: 200px;
    height: 100%;
    background-color: inherit;
    position: relative;
    padding: 60px 60px;
    display: flex;
    flex-direction: column;
    &-north {
      img {
        width: 66px;
      }
    }
  }
  &-content {
    font-size: 14px;
    margin-right: 80px;
    padding-bottom: 70px;
    width: 100%;
    display: flex;
    flex-direction: column;
    .search-result {
      width: 90%;

      .search-result-header-left {
        margin-block: 20px;
      }
    }
  }
}

//TODO mobile-size
@include mq($from: desktop-large) {
  .search {
    &-content {
      .product-selector {
        img.product-display--img {
          object-fit: contain;
          width: auto;
          max-width: 100%;
        }
      }
    }
  }
}

//TODO mobile-size
@include mq($until: desktop-large) {
  .search {
    &-content {
      .product-selector {
        img.product-display--img {
          object-fit: contain;
          width: auto;
          max-width: 100%;
        }
      }
    }
  }
}
//TODO mobile-size
@include mq($from: desktop-large) {
  .search {
    &-content {
      .product-selector {
        .carousel.carousel-slider {
          width: 700px !important;
        }
      }
    }
  }
}
//TODO mobile-size
@include mq($until: desktop) {
  .search {
    &-content {
      .product-selector {
        img.product-display--img {
          object-fit: contain;
          width: auto;
          max-width: 100%;
        }
      }
    }
  }
}
//TODO mobile-size
@include mq($until: desktop-sm) {
  .search {
    &-content {
      .product-selector {
        img.product-display--img {
          object-fit: contain;
          width: auto;
          max-width: 100%;
        }
      }
    }
  }
}

@include mq($until: tablet) {
  .search {
    &-header {
      justify-content: unset;
    }
    &-intro {
      display: none;
    }
    overflow: hidden;
    &-content {
      padding-bottom: 30px;
      margin-right: 0;
      height: auto;
    }
  }
}
