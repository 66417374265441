.add-user {
  background-color: $color-admin-gray;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .custom-header {
    margin-top: inherit;
    padding: 40px 80px;
    box-shadow: 0 4px 4px 0px #5f4a4a14;

    a,
    div {
      color: $color-dark-grey;

      p {
        border-color: $color-dark-grey;
      }
    }
  }
  &-content {
    margin: auto;
    padding-bottom: 150px;

    &-page {
      margin: 0 auto;
      width: 850px;
      margin-top: 30px;

      .back-button {
        position: absolute;
        top: 50px;
        display: flex;
        border: none;
        background: none;
        align-items: center;
        column-gap: 9px;
        p {
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 26px;
        }
      }
      h2 {
        font-size: 26px;
        margin-bottom: 20px;
      }
    }
  }

  .banner-error {
    padding: 10px;
    background-color: $color-support-alert-50;
    color: white;
    max-width: 525px;
  }
  .banner-success {
    padding: 10px;
    background-color: $color-support-success;
    color: white;
    max-width: 525px;
  }

  .form-wrapper {
    margin: 0 auto;
    width: 850px;
    background-color: $color-white;
    padding: 24px;
    h3 {
      font-size: 26px;
      margin-block: 30px;
    }
    form {
      display: flex;
      flex-direction: column;
      row-gap: 32px;
      .field {
        width: 525px;
        &.small {
          width: 310px;

          .b-select2-container {
            ul {
              width: 310px;
              top: 39px;
              left: -15px;
              color: $color-light-charcoal-grey;
              font-weight: 600;
            }
          }
        }
        .select-country {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          input {
            margin-left: 20px;
            padding-top: 8px;
          }
        }

        fieldset {
          border-width: 2px;
          border-color: $color-grey;
          color: $color-medium-grey;
          &.error {
            color: $color-support-alert-50;
            border: 1px solid $color-support-alert-50;
            padding-left: 14px;
          }
        }
        .error {
          color: $color-support-alert-50;
        }
        textarea,
        input {
          color: $color-light-charcoal-grey;
          font-weight: 600;
          width: 100%;
          border: none;
          font-size: 14px;
          padding: 0 0 10px 0;
          &.disabled {
            color: black;
            cursor: grab;
          }
        }
      }
      .device-title {
        color: $color-medium-grey;
      }
      .container-checkbox {
        max-width: 530px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;
        gap: 50px;
        margin-bottom: 20px;
      }
      .btn-container {
        display: flex;
        gap: 20px;
      }

      button.submit {
        background-color: $color-primary-blue;
        padding: 15px 40px;
        color: $color-white;
        text-transform: uppercase;
        size: 14px;
        line-height: 26px;
        border-radius: 4px;
        align-self: flex-start;
        border: none;
        font-weight: 700;
        font-size: 14px;
        &.disabled {
          opacity: 0.4;
        }
      }
    }
  }
}

@include mq($until: tablet) {
  .add-user {
    background-color: $color-admin-gray;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-inline: 10px;
    .custom-header {
      margin-top: inherit;
      padding: 40px 80px;
      box-shadow: 0 4px 4px 0px #5f4a4a14;

      a,
      div {
        color: $color-dark-grey;

        p {
          border-color: $color-dark-grey;
        }
      }
    }
    &-content {
      margin: auto;

      &-page {
        margin: 0 auto;
        margin-top: 30px;
        width: 100%;
        display: flex;

        .back-button {
          position: absolute;
          top: 80px;
          display: flex;
          border: none;
          background: none;
          align-items: center;
          column-gap: 9px;
          z-index: 1000000;
          p {
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
          }
        }
        h2 {
          font-size: 26px;
          margin-bottom: 20px;
        }
      }
    }
    .form-wrapper {
      width: 100%;
      form {
        .field {
          width: 100%;
        }
      }
    }
  }
}
