.country-select-container {
    height: 24px !important;
    width: 60px;
    margin-right: 10px;
    border: none !important;
  }
  
  .country-select-button {
    width: 60px;
    display: flex;
    height: 24px !important;
    border: none !important;
  }
  
  .country-select-option-label-container {
    display: flex;
    align-items: center;
  }
  
  .country-select-option-label {
    margin-left: 10px;
    overflow: hidden;
    white-space: nowrap;
  }
  
  .country-select-input-wrapper {
    z-index: -1;
    position: fixed;
    visibility: hidden;
    margin-left: 4000px;
    color: transparent !important;
  }
  
  input {
    color: transparent;
  }
  