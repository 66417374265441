.mobile-bullets {
  display: none;
}

@include mq($until: tablet) {
  .mobile-bullets {
    display: flex !important;
    align-self: center;
    ul.dots {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: row;
      column-gap: 16px;
      li {
        height: 10px;
        width: 10px;
        background: transparent;
        border-radius: 50%;
        margin-left: 3px;
        margin-right: 3px;
        cursor: pointer;
        border: 1px solid $color-white;

        &:hover {
          background: #bbb;
        }

        &.active {
          background: $color-white;
        }
      }
    }
  }
}
